import React from "react";
import { useNavigate } from "react-router-dom";
import Footer from "./Components/Footer";
import PageHeader from "./Components/PageHeader";
import { useState } from "react";
// import { toast } from "react-toastify";
import { toast } from "react-hot-toast";
import useUpdatePassword from "../hooks/useUpdatePassword";

export default function Changepassword() {
  const navigate = useNavigate();
  const { mutate: updatePassword } = useUpdatePassword();
  let userId = sessionStorage.getItem("UUID");
  let userData = JSON.parse(sessionStorage.getItem("DATA"))?.data;

  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  //////////////change password //////////////////
  function myFormData(e) {
    e.preventDefault();
    const userPassword = userData.password;

    if (oldPassword !== userPassword) {
      toast.error("Old password is incorrect");
      return;
    }

    if (newPassword !== confirmPassword) {
      toast.error("New password and confirm password do not match");
      return;
    }

    if (confirmPassword.length < 6) {
      toast.error("Password must be at least 6 characters long");
      return;
    }

    const payload = {
      user_id: userId,
      old_password: oldPassword,
      new_password: confirmPassword,
      type: "user",
    };

    updatePassword(payload, {
      onSuccess: () => {
        toast.success("Password changed successfully");
        navigate("/MainMenu");
      },
      onError: (error) => {
        toast.error(error);
      },
    });
  }

  return (
    <>
      <div className="" style={{ backgroundColor: "#E9E9E9" }}>
        <PageHeader title="LEDGER" />
        <div
          className="position-relative userTheme"
          style={{ paddingTop: "98px", paddingBottom: "48px" }}
        >
          <div className="position-relative">
            <div className="container-fluid table-responsive p-0">
              <form onSubmit={myFormData}>
                <table
                  width="100%"
                  border="0"
                  cellspacing="0"
                  cellpadding="0"
                  style={{
                    backgroundColor: "#e9e9e9",
                    height: "40px",
                    borderCollapse: "separate",
                  }}
                >
                  <tbody>
                    <tr>
                      <td
                        height="35"
                        align="center"
                        bgcolor="#888399"
                        className="TeamCombo"
                        colSpan="4"
                      >
                        <p className="style13">CHANGE PASSWORD </p>
                      </td>
                    </tr>
                  </tbody>
                  <tbody>
                    <tr>
                      <form action="#" method="POST" id="frm"></form>
                      <td width="1%" bgcolor="#FFF">
                        &nbsp;
                      </td>
                      <td bgcolor="#FFF">&nbsp;</td>
                      <td width="50%" bgcolor="#FFF">
                        &nbsp;
                      </td>
                      <td bgcolor="#FFF" className="FontTextBlack10px">
                        &nbsp;
                      </td>
                    </tr>
                    <tr>
                      <td bgcolor="#FFF" className="FontTextBlack10px">
                        &nbsp;
                      </td>
                      <td
                        width="49%"
                        align="center"
                        height="35"
                        bgcolor="#FFF"
                        className="FontTextBlack10px"
                      >
                        OLD. PASSWORD
                      </td>
                      <td align="center" bgcolor="#FFF">
                        <input
                          type="password"
                          id="c_pass"
                          className="form-control1"
                          name="current_pass"
                          onChange={(e) => {
                            setOldPassword(e.target.value);
                          }}
                          required
                        />
                      </td>
                      <td bgcolor="#FFF" className="FontTextBlack10px">
                        &nbsp;
                      </td>
                    </tr>
                    <tr>
                      <td bgcolor="#FFF" className="FontTextBlack10px">
                        &nbsp;
                      </td>
                      <td
                        height="35"
                        align="center"
                        bgcolor="#FFF"
                        className="FontTextBlack10px"
                      >
                        NEW PASSWORD
                      </td>
                      <td align="center" bgcolor="#FFF">
                        <input
                          type="password"
                          id="pass1"
                          className="form-control1"
                          min="2"
                          required
                          name="new_pass"
                          onChange={(e) => {
                            setNewPassword(e.target.value);
                          }}
                        />
                      </td>
                      <td bgcolor="#FFF" className="FontTextBlack10px">
                        &nbsp;
                      </td>
                    </tr>
                    <tr>
                      <td bgcolor="#FFF" className="FontTextBlack10px">
                        &nbsp;
                      </td>
                      <td
                        height="35"
                        align="center"
                        bgcolor="#FFF"
                        className="FontTextBlack10px"
                      >
                        CON. PASSWORD
                      </td>
                      <td align="center" bgcolor="#FFF">
                        <input
                          type="password"
                          id="pass2"
                          className="form-control1"
                          name="confirm"
                          onChange={(e) => {
                            setConfirmPassword(e.target.value);
                          }}
                          required
                        />

                        {/* <input type="hidden" id="id" className="form-control1" name="confirm" min="2" required="" /> */}
                      </td>
                      <td bgcolor="#FFF" className="FontTextBlack10px">
                        &nbsp;
                      </td>
                    </tr>
                    <tr>
                      <td
                        colSpan="4"
                        align="center"
                        bgcolor="#FFF"
                        className="FontTextBlack10px"
                      >
                        &nbsp;
                      </td>
                    </tr>
                    <tr>
                      <td height="25">&nbsp;</td>
                    </tr>
                    <tr>
                      <td height="45" colSpan={3} className="text-center">
                        <div
                          id="msgCon"
                          align="center"
                          bgcolor="#6A9AA4 "
                          class="success"
                        ></div>
                        <div class="menu" id="menu" align="center">
                          <ul
                            class="nav"
                            style={{
                              justifyContent: "center",
                              background: "#e9e9e9",
                            }}
                          >
                            <li class="active">
                              <button
                                class="mybtn "
                                type="submit"
                                style={{
                                  height: "45px",
                                  width: "132px",
                                  borderRadius: "5px",
                                  backgroundColor: "#4caf50",
                                  color: "white",
                                  border: "1px solid #4caf50",
                                  marginBottom: "15px",
                                }}
                              >
                                CHANGE PASSWORD
                              </button>
                            </li>
                          </ul>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </form>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
}
